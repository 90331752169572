import * as $ from 'jquery/dist/jquery.js';
import Inputmask from 'inputmask/dist/inputmask/jquery.inputmask.js';

$(document).ready(function(){
  $('.ic-input').inputmask("999999-99-9999");

  $('.ic-input').click(function() {
    $('.container-fluid-div').scrollTop($('.ic-input')[0].scrollHeight);
  });

});